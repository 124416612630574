<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<div class="performance__content overview-intro">
				<h2 class="performance__title">we stand at the center of <br> <span class="primary-base">the financial marketplaces</span></h2>
				<p>DTCC plays a pivotal role in protecting and supporting the growth of the global financial markets.</p>
			</div>
			<div class="performance__graphic">
				<div class="overview-graphic">
					<img src="~@/assets/logo_dtcc--black.svg" alt="DTCC" class="overview-logo" width="196" />
					<div class="overview-circle overview-circle--purple">
						Repository &amp; Derivatives Services
					</div>
					<div class="overview-circle overview-circle--green">
						Settlement &amp; Asset Services
					</div>
					<div class="overview-circle overview-circle--orange">
						Wealth Management Services
					</div>
					<div class="overview-circle overview-circle--red">
						Clearing Services
					</div>
					<div class="overview-circle overview-circle--plum">
						Data Services
					</div>
					<div class="overview-circle overview-circle--aqua">
						Institutional Trade Processing
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PerformanceOverview',
}
</script>

<style scoped lang="scss">
.overview {
	&-graphic {
		@include ratio('square');
		position: relative;
		max-height: 450px;
		max-width: 450px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;

		&:after {
			content: '';
			position: absolute;
			z-index: 5;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border: 2px dashed #EEEEEE;
			border-radius: 50%;
			display: block;

			animation-name: rotate;
			animation-duration: 30s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}

	&-logo {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}

	&-intro {
		@include media-query-min-width('tablet') {
			@include gutter('padding-right', 3);
		}
	}


	&-circle {
		background-color: white;
		width: 100px;
		height: 100px;
		position: absolute;
		transform: translate3d(-50%, -50%, 0);
		text-align: center;
		border-radius: 50%;
		border: 3px solid red;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
		color: color_('text');
		padding: 6px;
		z-index: 10;

		&--green {
			border-color: #7AC143;
		}

		&--orange {
			border-color: #F78E1E;
		}

		&--red {
			border-color: #E85F43;
		}

		&--plum {
			border-color: #4F1956;
		}

		&--aqua {
			border-color: #00B0AD;
		}

		&--purple {
			border-color: #A0285A;
		}

		&:nth-of-type(1) {
			left: 5%;
			top: 25%;
		}

		&:nth-of-type(2) {
			left: 50%;
			top: 0;
		}

		&:nth-of-type(3) {
			left: 95%;
			top: 25%;
		}

		&:nth-of-type(4) {
			left: 95%;
			top: 75%;
		}

		&:nth-of-type(5) {
			left: 50%;
			top: 100%;
		}

		&:nth-of-type(6) {
			left: 5%;
			top: 75%;
		}

	}

}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
